<span matDialogTitle>{{ data.title }}</span>
<mat-dialog-content>
    <p [innerHTML]="data.message" class="simple-dialog-message"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (data.cancel) {
        <button (click)="submit(false)" mat-stroked-button type="button">
            {{ data.cancel }}
        </button>
    }
    <button (click)="submit(true)" color="primary" mat-stroked-button type="button">
        {{ data.ok }}
    </button>
</mat-dialog-actions>
