import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';

import { SimpleDialogComponent, SimpleDialogData, SimpleDialogResult } from './simple-dialog/simple-dialog.component';

type ErrorActionCreator<T extends string> = ActionCreator<
    T,
    (props: { error: string }) => { error: string } & Action<T>
>;

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private store = inject(Store);
    private transloco = inject(TranslocoService);
    private matDialog = inject(MatDialog);

    handleError<T extends string>(
        originalAction: Action,
        failActionCreator: ErrorActionCreator<T>,
        error: unknown,
        options?: { duration?: number; canRetry?: boolean },
    ): Observable<Action> {
        const retry = options?.canRetry ?? true;
        const clickAction = retry ? originalAction : null;
        const message = this.getMessage(error);
        const errorAction = failActionCreator({ error: message });
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
            // AuthInterceptor will have redirected us to the login page, so we don't need to show an error message
            return of(errorAction);
        }
        return of(this.showErrorDialog(message, clickAction)).pipe(map(() => errorAction));
    }

    showErrorDialog(message: string, failedAction?: Action | null): MatDialogRef<SimpleDialogComponent> {
        let ok: string;
        let cancel: string | undefined = undefined;
        if (failedAction) {
            ok = this.transloco.translate('RETRY');
            cancel = this.transloco.translate('CANCEL');
        } else {
            ok = this.transloco.translate('CLOSE');
        }
        const data: SimpleDialogData = {
            ok,
            cancel,
            message,
            title: this.transloco.translate('ERROR'),
        };
        const dialog = this.matDialog.open<SimpleDialogComponent, SimpleDialogData, SimpleDialogResult>(
            SimpleDialogComponent,
            { data },
        );
        if (failedAction) {
            dialog.afterClosed().subscribe((result) => {
                if (result?.submitted) {
                    this.store.dispatch(failedAction);
                }
            });
        }
        return dialog;
    }

    getMessage(error: unknown): string {
        if (typeof error === 'string') {
            return error;
        } else if (error instanceof HttpErrorResponse && error.error && typeof error.error === 'object') {
            if ('message' in error.error) {
                const msg = error.error.message;
                if (typeof msg === 'string') {
                    return msg;
                } else if (Array.isArray(msg)) {
                    return msg.join(', ');
                }
            }
        }
        console.error(error);
        return this.transloco.translate('UNKNOWN_ERROR');
    }
}
