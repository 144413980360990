import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export interface SimpleDialogData {
    title?: string;
    message: string;
    ok: string;
    cancel?: string;
}

export interface SimpleDialogResultValue {
    submitted: boolean;
}

export type SimpleDialogResult = undefined | SimpleDialogResultValue;

@Component({
    templateUrl: 'simple-dialog.component.html',
    styles: `
        .simple-dialog-message {
            white-space: pre-wrap;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class SimpleDialogComponent {
    private dialogRef = inject(MatDialogRef<SimpleDialogComponent>);
    public data = inject<SimpleDialogData>(MAT_DIALOG_DATA);

    submit(submitted: boolean) {
        const result: SimpleDialogResult = { submitted };
        this.dialogRef.close(result);
    }
}
